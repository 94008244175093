.__json-pretty__ {
	line-height: 1.3;
	color: rgba(248, 248, 242, 1);
	background: #1e1e1e;
	overflow: auto;
	padding: 8px 16px;
	box-sizing: border-box;
	margin: 0;
}

.__json-key__ {
	color: rgb(237, 139, 19);
}

.__json-value__ {
	color: rgba(253, 176, 130, 1);
}

.__json-string__ {
	color: rgb(198, 198, 198);
}

.__json-boolean__ {
	color: rgb(24, 121, 219);
}

.__json-pretty-error__ {
	line-height: 1.3;
	color: rgba(248, 248, 242, 1);
	background: #1e1e1e;
	overflow: auto;
}

.fadeInRight {
	-webkit-animation-name: fadeInRight;
	animation-name: fadeInRight;
	-webkit-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

@-webkit-keyframes fadeInRight {
	0% {
		-webkit-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0);
	}

	100% {
		-webkit-transform: none;
		transform: none;
	}
}

@keyframes fadeInRight {
	0% {
		-webkit-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0);
	}

	100% {
		-webkit-transform: none;
		transform: none;
	}
}

.selected {
	background: #5faf42 !important;
	cursor: pointer;
	color: white !important;
}
