div.google-visualization-tooltip {
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    box-shadow: none;
    border: none;
    border-radius: 5px;
    font-size: 12px;
    padding: 0.5rem;
    height: auto;
    display: flex;
    align-items: center;
}